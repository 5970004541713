import logo from './logo.svg';
import './App.css';
import Login from './pages/auth/Login';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Customer from './pages/Customer';
import CustomerDetail from './pages/CustomerDetail';
import Settings from './pages/Settings';
import { ChakraProvider } from '@chakra-ui/react'
import { PrivateRoute } from './components/PrivateRoute';

function App() {
  return (
    <>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>            
          <Route path="/" element={
            <PrivateRoute>
                                <Dashboard />
                                
                            </PrivateRoute>

           } />
           <Route path="/dashboard" element={
            <PrivateRoute>
                                <Dashboard />
                                
                            </PrivateRoute>

           } />
          <Route path="/customer" element={

            <PrivateRoute>
                                <Customer />
                                
                            </PrivateRoute>

            } />
          <Route path="/settings" element={

              <PrivateRoute>
                                <Settings />
                                
                            </PrivateRoute>
            } />
          <Route path="/login" element={<Login />} />
          <Route path="/CustomerDetail/:id" element={

              <PrivateRoute>
                                <CustomerDetail />
                                
                            </PrivateRoute>

            } />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
    </>
  );
}

export default App;

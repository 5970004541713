import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';




function Edit({rs,loadList,meCloseEdit}){

	const [brandList,setBrandList] = useState([]);
	const [weaponTypeList,setWeaponTypeList] = useState([]);
	const [caliberList,setCaliberList] = useState([]);

	useEffect(() => {
        loadBrandList()
       	loadWeaponTypeList()
       	loadCaliberList()
        
    }, []);

  const loadBrandList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/weapon-brand`;
      const data  = await fetchWrapper.get(url)
      setBrandList(data)
  }

  const loadWeaponTypeList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/weapon-type`;
      const data  = await fetchWrapper.get(url)
      setWeaponTypeList(data)
  }

  const loadCaliberList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/caliber`;
      const data  = await fetchWrapper.get(url)
      setCaliberList(data)
  }


	const [formData, setFormData] = useState({
    brand_id: rs.brand_id,
    name: rs.name,
    weapon_type_id: rs.weapon_type_id,
    caliber_id: rs.caliber_id,
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   brand_id: Yup.string().required('Required'),
	   name: Yup.string().required('Required'),
	   weapon_type_id: Yup.string().required('Required'),
	   caliber_id: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/model/${rs.id}`;
  		 await fetchWrapper.put(url,data)
  		 loadList()
  		 meCloseEdit();
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			 	<InputText name="name" title="Model"  />

			 	<SelectField list={brandList} name="brand_id" title="Brand"  />

			 	<SelectField list={weaponTypeList} name="weapon_type_id" title="WeaponType"  />

			 	<SelectField list={caliberList} name="caliber_id" title="Caliber"  />

		         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

		          		<Button py="10px" px="25px" color="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
          		</Button>

			</Form>

			</>

	)
}

export default Edit;
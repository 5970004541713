import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { HStack, Box, Text } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';




function LicenseCreate({loadList,meClose,customer_id,customerName}){

	const [issueAuthorityList,setIssueAuthorityList] = useState([]);
	const [weaponTypeList,setWeaponTypeList] = useState([]);
	const [jurisdicationList,setJurisdicationList] = useState([]);
	const [issuanceQuotaList,setIssuanceQuotaList] = useState([]);

	useEffect(() => {
        loadIssueAuthorityList()
        loadWeaponTypeList()
        loadJurisdicationList()
        loadIssuanceQuotaList()

        
    }, []);

  const loadIssueAuthorityList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/issue-authority`;
      const data  = await fetchWrapper.get(url)
      setIssueAuthorityList(data)
  }

  const loadWeaponTypeList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/weapon-type`;
      const data  = await fetchWrapper.get(url)
      setWeaponTypeList(data)
  }

  const loadJurisdicationList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/jurisdiction`;
      const data  = await fetchWrapper.get(url)
      setJurisdicationList(data)
  }

  const loadIssuanceQuotaList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/issue-quota`;
      const data  = await fetchWrapper.get(url)
      setIssuanceQuotaList(data)
  }

	const [formData, setFormData] = useState({
    authority_id: '',
    tracking_number: '',
    license_number: '',
    weapon_type_id:'',
    ammunition_limit:'',
    date_issue: '',
    valid_till: '',
    jurisdiction_id:'',
    issue_quota_id:'',
    customer_id:customer_id,
    
  });

  const FormSchema = Yup.object().shape({
	   authority_id: Yup.string().required('Required'),
	   tracking_number: Yup.string().required('Required'),
	   license_number: Yup.string().required('Required'),
	   weapon_type_id: Yup.string().required('Required'),
	   ammunition_limit: Yup.string().required('Required'),
	   date_issue: Yup.string().required('Required'),
	   valid_till: Yup.string().required('Required'),
	   jurisdiction_id: Yup.string().required('Required'),
	   issue_quota_id: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/license`;
  		 await fetchWrapper.post(url,data)
  		 meClose();
  		 loadList()
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
  		 
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			 	<Text fontSize="12px" fontWeight="500" fontFamily="Lato, sans-serif" mb="25px"><b>License For:</b> {customerName}</Text>

			 	<SelectField list={issueAuthorityList} name="authority_id" title="Issuing Authority"  />

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<InputText name="tracking_number" title="Tracking Number"  />
			 		</Box>

			 		<Box w="48%">
			 			<InputText name="license_number" title="License Number"  />
			 		</Box>
			 	</HStack>
			 	
			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<SelectField list={weaponTypeList} name="weapon_type_id" title="Weapon Type"  />
			 		</Box>

			 		<Box w="48%">
			 			<InputText name="ammunition_limit" title="Ammunition Limit"  />
			 		</Box>
			 	</HStack>

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<InputDateField inputProps={{type:'date'}}  name="date_issue" title="Date of Issuance"  />
			 		</Box>

			 		<Box w="48%">
			 			<InputDateField  inputProps={{type:'date'}} name="valid_till" title="Valid Till"  />
			 		</Box>
			 	</HStack>

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<SelectField list={jurisdicationList} name="jurisdiction_id" title="Jurisdiction "  />
			 		</Box>

			 		<Box w="48%">
			 			<SelectField list={issuanceQuotaList} name="issue_quota_id" title="Issuance Quota"  />
			 		</Box>
			 	</HStack>
			 	


         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            
		            type='submit'
          		>
           		 Save
          		</Button>

			</Form>

			</>

	)
}

export default LicenseCreate;
import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
} from '@chakra-ui/react'
import "../../index.css";
import List from './issuanceauthority/List';

function IssuanceAuthority(){
	return(
		<>
			<List />
		</>
	)
}

export default IssuanceAuthority;
import React,{useEffect,useState} from 'react';
import Header from '../components/Header';
import { HStack, Box, Image, Text, Button, Divider } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import "../index.css";
import { Link,useLocation,useNavigation } from "react-router-dom";
import Authorityimg from '../images/authority_img.png';
import Profileimg from '../images/profile_img.png';
import Caliber9mmImage from '../images/9mm.png';
import Caliber556Image from '../images/556.png';
import { BsPlusCircleFill, BsFillCameraFill } from "react-icons/bs";
import CustomerModalLink from '../components/CustomerModalLink'
import LicenseCreate from './customer/license/Create'
import LicenseList from './customer/license/List'
import WeaponCreate from './customer/weapon/Create'
import WeaponList from './customer/weapon/List'
import Edit from './customer/license/Edit'
import { fetchWrapper } from '../helpers';
import moment from 'moment';

function CustomerDetail({ meClose }){
	const {state} = useLocation();
	const id = state.id
	const [list,setList] = useState([])
	const [customerDetail,setCustomerDetail] = useState({})
  useEffect(() => {
  			loadCustomer();
        loadList()
       
       
        
    }, [id]);

  const loadCustomer = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/customer/${id}`;
      const data  = await fetchWrapper.get(url)
      setCustomerDetail(data)
  }

  const loadList = async () => {
      // const url = `${process.env.REACT_APP_API_URL}/api/license?customer${id}`;
      // const data  = await fetchWrapper.get(url)
      // setList(data)
  }

	return(
		<>
			<Header />

			<div className="body_section">
				<HStack mb="25px">
					<Box>
						<Link to="/Customer">Back to Customers</Link>
					</Box>	
				</HStack>

				<HStack alignItems="top">
					<Box className="left_section" me="20px">
						<Box p="15px" borderWidth="1px" borderStyle="solid" borderColor="#ccc" borderRadius="5px">
							<Box position="relative" w="200px" h="200px" margin="auto">
								<Image src={Profileimg} />
								<Box py="3px" pl="10px" width="100%" bg="rgba(0, 0, 0, 0.75)" position="absolute" bottom="0px">
									<BsFillCameraFill w="20px" h="20px" color="#fff" />
								</Box>
							</Box>

							<Text fontSize="20px" fontWeight="600" color="#000" textAlign="center" my="15px">{customerDetail.first_name} {customerDetail.last_name}</Text>

							<Divider borderStyle="solid" borderColor="#ccc" />

							<Box my="15px">
								<Text fontSize="14px" fontWeight="700" color="#000" m="0px" mb="15px">Basic Information</Text>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">Contact No:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.phone}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">CNIC:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.cnic}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">Date of Birth:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{moment(customerDetail.dob).format('DD MMMM, YYYY')}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">Address:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.address}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">City:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.city_id}</Text>
									</Box>
								</HStack>
							</Box>

							<Divider borderStyle="solid" borderColor="#ccc" />

							<Box my="15px">
								<Text fontSize="14px" fontWeight="700" color="#000" m="0px" mb="15px">Files</Text>

								<HStack justifyContent="space-between" alignItems="center" mb="10px">
									<Box>
										<Text fontSize="14px" fontWeight="700" color="#000">CNIC Front:</Text>
									</Box>

									<Box>
										<Box w="110px" bg="#20E100" py="3px" borderRadius="25px">
											<Text textAlign="center" color="#fff" fontSize="14px" fontWeight="500">Uploaded</Text>
										</Box>
									</Box>

									<Box>
										<Link>View File</Link>
									</Box>
								</HStack>

								<HStack justifyContent="space-between" alignItems="center">
									<Box>
										<Text fontSize="14px" fontWeight="700" color="#000">CNIC Back:</Text>
									</Box>

									<Box>
										<Box w="110px" bg="#EC0000" py="3px" borderRadius="25px">
											<Text textAlign="center" color="#fff" fontSize="14px" fontWeight="500">Not Uploaded</Text>
										</Box>
									</Box>

									<Box>
										<Link>View File</Link>
									</Box>
								</HStack>
							</Box>
						</Box>
					</Box>

					<Box className="right_section">
						<Box mb="20px" borderWidth={1} borderStyle="solid" borderColor="#ccc" borderRadius="5px">


							<LicenseList customerName={`${customerDetail.first_name} ${customerDetail.last_name}`} customer_id={id} />
						</Box>

						<Box mb="20px" borderWidth={1} borderStyle="solid" borderColor="#ccc" borderRadius="5px">
							

							<WeaponList customerName={`${customerDetail.first_name} ${customerDetail.last_name}`} customer_id={id} />
						</Box>

						<Box mb="20px" borderWidth={1} borderStyle="solid" borderColor="#ccc" borderRadius="5px">
							<HStack py="10px" px="20px" justifyContent="space-between" bg="#001F3F" borderBottomWidth="1px" borderColor="#ccc">
								<Box>
									<Text fontSize="14px" fontWeight="500" color="#fff" margin="0px">Ammunation</Text>
								</Box>

								<Box>
									<a className="add_license_link"><BsPlusCircleFill /> <Text m="0px" fontSize="10px" fontWeight="800" textTransform="UpperCase">Add Ammunition</Text></a>
								</Box>
							</HStack>

							<div className="inner_table_section">
								<TableContainer width="100%">
								  <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
								    <Thead>
								      <Tr>
								        <Th>Caliber</Th>
								        <Th>In Stock</Th>
								        <Th>License No</Th>
								        <Th>Ammo Limit</Th>
								        <Th>Limit Available</Th>
								      </Tr>
								    </Thead>
								    <Tbody>
								      <Tr>
								        <Td>
								        	<Image w="55px" h="25px" src={Caliber9mmImage} />
								        </Td>
								        <Td>350</Td>
								        <Td>FA-123456</Td>
								        <Td>500</Td>
								        <Td>150</Td>
								      </Tr>
								      <Tr>
								        <Td>
								        	<Image w="55px" h="25px" src={Caliber556Image} />
								        </Td>
								        <Td>350</Td>
								        <Td>FA-123456</Td>
								        <Td>500</Td>
								        <Td>150</Td>
								      </Tr>
								    </Tbody>
								  </Table>
								</TableContainer>
							</div>
						</Box>
					</Box>
				</HStack>
			</div>
			
		</>
	)
}

export default CustomerDetail;
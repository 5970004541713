import React,{useState} from 'react';
import { HStack, Box, Image } from '@chakra-ui/react';
import * as Yup from 'yup';
import { fetchWrapper } from '../../helpers';
import { Link, useNavigate } from "react-router-dom";
import {
  
  Button
} from '@chakra-ui/react';
import logo from '../../images/logo.png';
import {InputText,Form} from '../../components/form'

function Login() {

  const navigate  = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    
    
  });

  const FormSchema = Yup.object().shape({
       email: Yup.string()
            .required('Required'),
      password: Yup.string()
            .required('Required'),
    
       
  });

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${process.env.REACT_APP_API_URL}/api/user/login`;
       const loginData = await fetchWrapper.post(url,data)

      
       localStorage.setItem("user", loginData.accessToken);
        navigate("/dashboard");
  }


  return (
    <HStack bg="#001F3F" h="100vh" justifyContent="center" flexDirection="column">
      <Box mb="30px">
        <Image src={logo} alt='Gun Guru' />
      </Box>

      <Box width="351px" bg="#fff" py="30" px="25">

      <Form
                  enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit={onSubmit}
       >
           <InputText name="email" title="User Name"  />
           <InputText inputProps={{type:'password'}}  name="password" title="Password"  />


           <Button type="submit" bg="#504F56" borderColor="#504F56" borderRadius="5px" fontSize="14px" fontWeight="500" color="#fff" width="100%" p="15" borderWidth="1px" borderStyle="solid">Login</Button>
       </Form>
        

        
      </Box>
    </HStack>
  );
}

export default Login;

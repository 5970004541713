import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';
import axios from 'axios';

function Create({loadList,meClose}){

	const [image, setImage] = useState({});

	const [formData, setFormData] = useState({
	
    name: '',
    logo: '',
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		 //   let  data = values
		  
	       
	     // const url = `${process.env.REACT_APP_API_URL}/api/issue-authority`;
  		 // await fetchWrapper.post(url,data)
  		 // loadList()
  		 // meClose();
		const url = `${process.env.REACT_APP_API_URL}/api/issue-authority`;

  		 let fd = new FormData();
       
          fd.append('logo', image);
          fd.append('name', values.name);
          
          console.log(image)
          const data = Object.fromEntries(fd)

         axios.post(url, fd).then((ss) => {
	      loadList();
	      meClose()
	      toast(`Record has been saved `, {
	        type: "info"
	  	});
      });
	}

	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	const handleChange = (e) => {
	    setImage(e.target.files[0]);
	  };

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Issuing Authority"  />

			 	<div className="field_section">
			 		<input type="file" id="upload-button" onChange={handleChange} />
			 	</div>

		         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Create;
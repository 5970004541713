import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { HStack, Box, Text } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';




function WeaponCreate({loadList,meClose,customer_id,customerName}){

	const [caliberList,setCaliberList] = useState([]);

	const [licenseList,setLicenseList] = useState([]);
	const [purchaseConditionList,setPurchaseConditionList] = useState([]);
	const [weaponTypeList,setWeaponTypeList] = useState([]);
	const [modelList,setModelList] = useState([]);
	const [makeList,setMakeList] = useState([]);

	useEffect(() => {
				loadCaliberList()
				loadLicenseList()
        loadPurchaseConditionList()
        loadWeaponTypeList()
        loadModelList()
        loadMakeList()

        
    }, []);

	const loadMakeList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/weapon-brand`;
      const data  = await fetchWrapper.get(url)
      setMakeList(data)
  }

	const loadCaliberList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/caliber`;
      const data  = await fetchWrapper.get(url)
      setCaliberList(data)
  }

  const loadLicenseList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/license?customer_id=${customer_id}`;
      const data  = await fetchWrapper.get(url)
      setLicenseList(data)
  }

  const loadPurchaseConditionList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/purchase-condition`;
      const data  = await fetchWrapper.get(url)
      setPurchaseConditionList(data)
  }

  const loadWeaponTypeList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/weapon-type`;
      const data  = await fetchWrapper.get(url)
      setWeaponTypeList(data)
  }

  const loadModelList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/model`;
      const data  = await fetchWrapper.get(url)
      setModelList(data)
  }

	const [formData, setFormData] = useState({
    cailber_id: '',
    weapon_type_id: '',
    license_id: '',
    weapon_no:'',
    make_id:'',
    model_id: '',
    purchase_date: '',
    purchase_condition_type_id:'',
    purchase_from:'',
    customer_id:customer_id,
    
  });

  const FormSchema = Yup.object().shape({
	   cailber_id: Yup.string().required('Required'),
	   weapon_type_id: Yup.string().required('Required'),
	   license_id: Yup.string().required('Required'),
	   weapon_no: Yup.string().required('Required'),
	   make_id: Yup.string().required('Required'),
	   model_id: Yup.string().required('Required'),
	   purchase_date: Yup.string().required('Required'),
	   purchase_condition_type_id: Yup.string().required('Required'),
	   purchase_from: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/weapon`;
  		 await fetchWrapper.post(url,data)
  		 meClose();
  		 loadList()
  		 //meClose();
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			 	<Text fontSize="12px" fontWeight="500" fontFamily="Lato, sans-serif" mb="25px"><b>Weapon For:</b> {customerName}</Text>

			 	

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<SelectField list={caliberList} name="cailber_id" title="Caliber"  />
			 		</Box>

			 		<Box w="48%">
			 			
			 			<SelectField list={weaponTypeList} name="weapon_type_id" title="Weapon Type"  />
			 		</Box>
			 	</HStack>
			 	
			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<SelectField list={licenseList} name="license_id" title="License"  />
			 		</Box>

			 		<Box w="48%">
			 			<InputText name="weapon_no" title="Weapon No"  />
			 		</Box>
			 	</HStack>

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<SelectField list={makeList} name="make_id" title="Make"  />
			 		</Box>

			 		<Box w="48%">
			 			<SelectField list={modelList} name="model_id" title="Model"  />
			 		</Box>
			 	</HStack>

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 		 <InputDateField inputProps={{type:'date'}}  name="purchase_date" title="Purchase Date"  />
			 			
			 		</Box>

			 		<Box w="48%">
			 			<SelectField list={purchaseConditionList} name="purchase_condition_type_id" title="Purchased Condition"  />
			 		</Box>
			 	</HStack>
			 	
			 	<InputText name="purchase_from" title="Purchased From"  />

         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            
		            type='submit'
          		>
           		 Save
          		</Button>

			</Form>

			</>

	)
}

export default WeaponCreate;
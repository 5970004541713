import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
const CustomerModalLink = ({ showModalButtonText, modalHeader, modalBody, icon, className,isOpen, onOpen, onClose }) => {
  //const { isOpen, onOpen, onClose } = useDisclosure();


  return (
    <>
      <a className={className} onClick={onOpen}>
        {icon}{showModalButtonText}
      </a>

      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalBody}

            
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomerModalLink;
import React from 'react';
import Header from '../components/Header';
import { HStack, Box, Image, Link, Text } from '@chakra-ui/react';
import "../index.css";

function Dashboard(){
	return(
		<>
			<Header />

			<div className="body_section">

				<HStack mb="30px">
					<Text fontSize="20px" fontWeight="500" color="#000">Dashboard</Text>
				</HStack>

				<HStack mb="30px">
					<Box borderWidth="1px" borderColor="#ccc" w="250px" me="15px">
						<Box py="15px" px="15px">
							<Text fontSize="36px" fontWeight="500" color="#000">100</Text>
						</Box>

						<Box bg="#001F3F" py="10px" px="15px">
							<Text color="#fff" fontSize="14px">Customers</Text>
						</Box>
					</Box>

					<Box borderWidth="1px" borderColor="#ccc" w="250px" me="15px">
						<Box py="15px" px="15px">
							<Text fontSize="36px" fontWeight="500" color="#000">200</Text>
						</Box>

						<Box bg="#001F3F" py="10px" px="15px">
							<Text color="#fff" fontSize="14px">Licenses</Text>
						</Box>
					</Box>

					<Box borderWidth="1px" borderColor="#ccc" w="250px" me="15px">
						<Box py="15px" px="15px">
							<Text fontSize="36px" fontWeight="500" color="#000">50</Text>
						</Box>

						<Box bg="#001F3F" py="10px" px="15px">
							<Text color="#fff" fontSize="14px">Renewal Due This Year</Text>
						</Box>
					</Box>
				</HStack>

				<Box borderWidth="1px" borderColor="#ccc" p="15px" w="60%" h="330px"> 
					<Text fontSize="14px" fontWeight="500" color="#000" mb="20px">License Count by Weapon Type</Text>
				</Box>
			</div>
		</>
	)
}

export default Dashboard;
import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../components/form'
import { HStack, Box } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';


function Create({loadList,meClose}){

	const [cityList,setCityList] = useState([]);

	useEffect(() => {
        loadCityList()
       
       
        
    }, []);

  const loadCityList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/city`;
      const data  = await fetchWrapper.get(url)
      setCityList(data)
  }

	const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    cnic: '',
    address:'',
    phone:'',
    
    city_id: '',
    dob: '',
    
    
  });

  const FormSchema = Yup.object().shape({
	   first_name: Yup.string().required('Required'),
	   last_name: Yup.string().required('Required'),
	   
	   cnic: Yup.string().required('Required'),
	   address: Yup.string().required('Required'),
	   city_id: Yup.string().required('Required'),
	   phone: Yup.string().required('Required'),
	   dob: Yup.string().required('Required')

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/customer`;
  		 await fetchWrapper.post(url,data)
  		 meClose();
  		 loadList()
  		 toast(`Record has been saved `, {
	        type: "dark"
	      });
  		 
  		 
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<InputText name="first_name" title="First Name"  />
			 		</Box>

			 		<Box w="48%">
			 			<InputText name="last_name" title="Last Name"  />
			 		</Box>
			 	</HStack>
			 	
			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<InputText name="cnic" title="CNIC"  />
			 		</Box>

			 		<Box w="48%">
			 			<InputDateField inputProps={{type:'date'}} name="dob" title="Date Of Birth"  />
			 		</Box>
			 	</HStack>
			 	

			 	<InputText name="address" title="Address"  />

			 	<HStack justifyContent="space-between">
			 		<Box w="48%">
			 			<SelectField list={cityList} name="city_id" title="City"  />
			 		</Box>
			 		<Box w="48%">
			 		<InputDateField  name="phone" title="Contact #"  />
			 		</Box>

			 	</HStack>


		         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Create;
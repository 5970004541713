import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {SelectField,InputText,Form} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';




function Edit({rs,loadList,meCloseEdit}){

	const [formData, setFormData] = useState({
    firstname: rs.firstname,
    lastname: rs.lastname,
    email: rs.email,
    phone: rs.phone,
   
    status_id: rs.status_id,
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   firstname: Yup.string().required('Required'),
	   lastname: Yup.string().required('Required'),
	   email: Yup.string().required('Required'),
	   phone: Yup.string().required('Required'),
	   
	   status_id: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/user/${rs.id}`;
  		 await fetchWrapper.put(url,data)
  		 loadList()
  		 meCloseEdit();
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
	}

	
	const defaultAccessList = {rows:[{id:1,name:'Active'},{id:0,name:'In Active'}]}



	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="firstname" title="First Name"  />

			 	<InputText name="lastname" title="Last Name"  />

			 	<InputText name="email" title="Email (Login)"  />

			 	

			 	

			 	

			 	<SelectField list={defaultAccessList} name="status_id" title="Status"  />

		         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

		          <Button py="10px" px="25px" color="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
          		</Button>


			</Form>

			</>

	)
}

export default Edit;
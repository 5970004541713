import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';




function Create({loadList,meClose}){

	const [countryList,setCountryList] = useState([]);

	useEffect(() => {
        loadCountryList()
       
       
        
    }, []);

  const loadCountryList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/country`;
      const data  = await fetchWrapper.get(url)
      setCountryList(data)
  }

	const [formData, setFormData] = useState({
    name: '',
    country_id: '',
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   country_id: Yup.string().required('Required'),
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/city`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
  		 meClose();
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="City"  />

			 	<SelectField list={countryList} name="country_id" title="Country"  />

		         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Create;
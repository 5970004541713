import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form} from '../../../components/form'
import axios from "axios";
import { fetchWrapper } from '../../../helpers';
import { toast } from "react-toastify";

function Edit({rs,loadList,meCloseEdit}){

	  const [image, setImage] = useState({});

	const [formData, setFormData] = useState({
	name: rs.name,
    id:rs.id
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   
	});









	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		 //   let  data = values
		  
	       
	     // const url = `${process.env.REACT_APP_API_URL}/api/issue-authority/${rs.id}`;
  		 // await fetchWrapper.put(url,data)
  		 // loadList()
  		 // meCloseEdit();

  		 const url = `${process.env.REACT_APP_API_URL}/api/issue-authority/${rs.id}`;
	    let fd = new FormData();

	    fd.append("logo", image);
	    fd.append("name", values.name);
	    fd.append("id", values.id);

	    // console.log(image);
	    const data = Object.fromEntries(fd);

	    axios.put(url, fd).then((ss) => {
	      loadList();
	      meCloseEdit();
	      toast(`Record has been saved `, {
	        type: "info"
	   		 });
  		});
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	  const handleChange = (e) => {
	    setImage(e.target.files[0]);
	  };

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Issuing Authority"  />

			 	<input type="file" id="upload-button" onChange={handleChange} />

		         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

		          		<Button py="10px" px="25px" color="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
          		</Button>

			</Form>

			</>

	)
}

export default Edit;